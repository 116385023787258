<template>
  <v-container>
    <v-card class="text-center">
      <v-card-title>
        <v-spacer />
        <span v-text="$t('multiproject.welcome.title')" />
        <v-spacer />
      </v-card-title>

      <v-card-text class="welcome-text">
        <p class="text-h5" v-text="projectNameText" />
        <p v-if="!ccpm" class="text-h5" v-text="projectStartText" />
        <v-btn
          v-if="isNotReady"
          :loading="loadingReady"
          :disabled="loadingReady"
          color="accent"
          @click="ready"
          v-text="$t('multiproject.welcome.readyButton')"
        />
        <v-btn
          v-else
          disabled
          color="accent"
          outlined
          v-text="waitingOthers"
        />
      </v-card-text>
    </v-card>

    <resource-list-row :value="game.resourceList" />

    <v-card
      v-for="{ gantt, id, isCurrentPlayer } in playerList"
      :key="`gantt-${id}`"
      :class="{ 'selected-gantt': isCurrentPlayer }"
      class="my-2"
    >
      <gantt-chart
        :value="gantt"
        :resource-list="game.resourceList"
        :height="25"
        :overlay="!isCurrentPlayer"
      />
    </v-card>
  </v-container>
</template>

<script>
import _sumBy from 'lodash/sumBy'

import AVAILABLE_STATUS from '../availableStatus'
import AVAILABLE_ACTIONS from '../availableActions'

import GanttChart from '../components/GanttChart'
import ResourceListRow from '../components/ResourceListRow'

export default {
  name: 'WelcomeContainer',
  components: {
    GanttChart,
    ResourceListRow,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
    ccpm: {
      type: Boolean,
      default: false,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    loadingReady: false,
  }),
  computed: {
    playerList () {
      return this.game.playerList.map(player => ({
        ...player,
        isCurrentPlayer: player.id === this.playerId,
      }))
    },
    currentPlayer () {
      return this.playerList.find(player => player.isCurrentPlayer)
    },
    isNotReady () {
      return this.currentPlayer.status === AVAILABLE_STATUS.notReady
    },
    projectNameText () {
      const { projectName } = this.currentPlayer
      return this.$t('multiproject.welcome.projectNameText', { projectName })
    },
    projectStartText () {
      const { weekStartPlanned } = this.currentPlayer
      return this.$t('multiproject.welcome.projectStartText', { weekStartPlanned })
    },
    readyPlayers () {
      return _sumBy(this.playerList, player => player.status !== AVAILABLE_STATUS.notReady)
    },
    waitingOthers () {
      return this.$t('multiproject.welcome.waitingOthers', {
        readyPlayers: this.readyPlayers,
        totalPlayers: this.playerList.length,
      })
    },
  },
  methods: {
    async ready () {
      this.loadingReady = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.ready)
      this.loadingReady = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .selected-gantt {
    border: 5px solid #506688!important;
  }
</style>
