<template>
  <v-card flat color="white" class="pa-2">
    <v-overlay
      v-if="overlay || overlayMessage"
      color="secondary"
      opacity="0.7"
      absolute
      z-index="4"
    >
      <v-sheet
        v-if="overlayMessage"
        class="rounded-lg pa-1 px-4 mx-auto transition-swing primary subtitle-1"
        width="100%"
        v-text="overlayMessage"
      />
    </v-overlay>
    <v-card-text class="px-0 py-0">
      <v-row no-gutters>
        <v-col
          v-for="(column, columnIndex) in columns"
          :key="`column-${columnIndex}`"
          :style="{ 'max-width': column.width }"
          class="px-2 mx-0 py-1 gantt-column"
        >
          <div
            v-for="(task, taskIndex) in column.tasks"
            :key="`task-${columnIndex}-${taskIndex}`"
            :style="{ 'width': task.width }"
          >
            <v-progress-linear
              :value="task.progress"
              :height="height"
              :color="$options.colors[task.color]"
              class="my-2"
            >
              <strong v-text="`${task.text}`" />
            </v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _maxBy from 'lodash/maxBy'
import _sumBy from 'lodash/sumBy'

export default {
  name: 'GanttChart',
  props: {
    value: {
      type: Array,
      required: true,
    },
    overlayMessage: {
      type: String,
      default: '',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 20,
    },
    resourceList: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    totalSize () {
      return _sumBy(this.value.map(tasks => _maxBy(tasks, 'size')), 'size')
    },
    columns () {
      return this.value.map(tasks => {
        const maxTask = _maxBy(tasks, 'size')
        const percent = Math.round((maxTask.size / this.totalSize) * 10000) / 100
        return {
          width: `${percent}%`,
          tasks: tasks.map(task => ({
            color: task.resource,
            text: this.appendPrefix(`${task.done}/${task.size}`, task.resource),
            progress: (task.done / task.size) * 100,
            width: `${Math.round((task.size / maxTask.size) * 10000) / 100}%`,
          })),
        }
      })
    },
  },
  methods: {
    appendPrefix (value, resource) {
      const { name } = this.resourceList.find(item => item.color === resource) ?? {}

      return name
        ? `(${name[0].toUpperCase()}) ${value}`
        : value
    },
  },
  colors: {
    red: 'red lighten-1',
    green: 'green lighten-1',
    yellow: 'amber lighten-1',
    blue: 'indigo lighten-1',
  },
}
</script>

<style lang="scss" scoped>
  .gantt-column {
    background: #f3f3f3;

    &:nth-child(odd) {
      background: #e8e8e8;
    }
  }
</style>
